@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZftWyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCu6KVjbNBYlgoKeg7z.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejYHtGyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZPsmyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzTtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eA.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3Ttw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvTtw.ttf) format('truetype');
}
:root {
  --rs-btn-primary-bg: #73dea8;
  --rs-btn-primary-hover-bg: #4ad48e;
  --rs-btn-primary-active-bg: #4ad48e;
  --rs-btn-primary-disabled-bg: #e7eaec80;
  --rs-btn-primary-text: #3b365c;
  --rs-btn-ghost-text: var(--rs-text-heading);
  --rs-btn-ghost-border: var(--rs-text-heading);
  --rs-btn-ghost-hover-border: var(--rs-text-heading);
  --rs-btn-ghost-hover-text: var(--rs-text-heading);
  --rs-btn-ghost-active-text: var(--rs-text-heading);
  --rs-btn-ghost-active-border: var(--rs-text-heading);
  --rs-toggle-checked-bg: #73dea8;
  --rs-toggle-checked-hover-bg: #4ad48e;
  --rs-toggle-checked-disabled-bg: #e7eaec80;
  --rs-text-link: #3b365c;
  --rs-text-link-hover: #170f40;
  --rs-navs-selected: #3b365c;
  --rs-bg-backdrop: rgba(0, 0, 0, 0.3);
  --rs-picker-value: #75d35e;
  --rs-listbox-option-selected-text: #64ce4a;
  --rs-listbox-option-selected-bg: rgba(115, 222, 168, 0.2);
  --rs-listbox-option-hover-text: #64ce4a;
  --rs-listbox-option-hover-bg: rgba(115, 222, 168, 0.08);
  --rs-input-focus-border: #73dea8;
  --rs-color-focus-ring: rgba(115, 222, 168, 0.2);
  --rs-state-focus-shadow: 0 0 0 3px rgba(115, 222, 168, 0.3);
  --rs-bg-active: #73dea8;
  --rs-checkbox-checked-bg: #73dea8;
  --rs-menuitem-active-bg: rgba(115, 222, 168, 0.08);
  --rs-menuitem-active-text: #64ce4a;
  --rs-picker-count-bg: #73dea8;
}
.leaflet-container {
  height: 100%;
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html,
body {
  width: 100%;
  background-color: #e7eaec;
}
body {
  height: 100dvh;
  font-family: 'Ubuntu', sans-serif;
}
.invisible {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.clearfix::after {
  content: '';
  clear: both;
  display: table;
}
input[type='date']::-webkit-calendar-picker-indicator {
  display: block;
  background-color: #e7eaec;
  height: 1.625rem;
  width: 1.625rem;
  padding: 0.3125rem;
}
input[type='date']::-webkit-datetime-edit-fields-wrapper {
  margin-right: 0.3125rem;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
input[type='date'] {
  padding: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.bold {
  font-weight: 700;
}
.click-input {
  margin-top: 0.5rem;
}
.click-input .action {
  color: #3b365c;
  text-decoration: underline;
}
.forgotten-passw {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rs-modal-wrapper {
  display: flex;
  align-items: center;
}
.rs-modal-wrapper .rs-modal-body {
  padding-top: 0.9375rem;
  padding-bottom: 1.5625rem;
}
.rs-carousel-label {
  background-color: #e7eaec;
}
.rs-carousel-label-wrapper input:checked ~ label {
  background-color: #73dea8;
}
.rs-dropdown-menu .rs-icon {
  margin-right: 10px;
}
.rs-dropdown-menu .rs-dropdown-item {
  display: flex;
  align-items: center;
}
.rs-dropdown-menu .rs-dropdown-item-content {
  color: inherit;
}
.rs-dropdown-menu .signed-in-as {
  flex-direction: column;
  align-items: flex-start;
  width: 10rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333333;
}
.rs-dropdown-menu .signed-in-as.rs-dropdown-item-disabled .rs-text {
  color: #333333;
}
.rs-btn.rs-btn-red {
  color: #fff;
}
.cursor-pointer {
  cursor: pointer;
}
.page-content-header {
  font-size: 1.75rem;
}
.page-subtitle {
  margin-top: 2.5rem;
  font-size: 1.125rem;
}
.disclaimer {
  font-size: 0.75rem;
  margin-top: 3.125rem;
}
.disclosure {
  color: var(--rs-btn-subtle-text);
  font-size: 0.875rem;
}
.info-icon {
  height: 1.25rem;
  cursor: pointer;
}
.input-base {
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  align-items: top;
  margin-top: 0.625rem;
}
.input-base:not(.label-on-top).label-container {
  display: flex;
  line-height: 2.3;
}
.input-base:not(.label-on-top).label-container .rs-checkbox {
  align-self: flex-start;
}
.input-base.label-on-top.dropdown-input .rs-form-control,
.input-base.label-on-top.dropdown-input .rs-form-control > div,
.input-base.label-on-top.dropdown-input .rs-picker-select {
  width: 100%;
  max-width: 100%;
}
.input-base.label-on-top.dropdown-input .rs-picker-has-value .rs-picker-toggle {
  width: calc(100% - 1rem);
}
.input-base .indent-icon {
  margin-top: 0.5625rem;
  margin-right: 0.625rem;
  color: lightgray;
}
.input-base:not(.label-on-top) {
  min-width: 35rem;
}
.input-base.label-on-top {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.6;
}
.input-base.label-on-top .input-base-label {
  padding-left: 0.625rem;
}
.input-base.label-on-top .input-base-label svg {
  margin-left: 0.625rem;
}
.input-base.label-on-top .input-base-item {
  width: 100% !important;
}
.input-base.label-on-top .invisible {
  display: none;
}
.input-base-label {
  display: inline-block;
}
.input-base .rs-toggle > span {
  margin-top: 0.4375rem;
}
.input-base .rs-input-group .rs-input-group-addon {
  min-width: 2.375rem;
  justify-content: center;
}
.input-base-item:not(.label-on-top) {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}
.input-base-item:not(.label-on-top) .rs-form-help-text {
  text-align: right;
  font-size: 0.8125rem;
  margin-bottom: 1.25rem;
}
.input-base-item:not(.label-on-top) .align-right {
  text-align: right;
}
.input-base-item:not(.label-on-top) .align-left {
  text-align: left;
}
.input-base-item:not(.label-on-top) > * {
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}
.input-base-item:not(.label-on-top) > div {
  width: 100%;
  margin-bottom: 0 !important;
}
.input-base-item:not(.label-on-top) > svg {
  margin-top: 0.5625rem;
}
.input-base .inline-edit-input {
  width: 100%;
  height: 100%;
}
.input-base .inline-edit-input span {
  padding: 0.4375rem 0.6875rem;
  display: inline-block;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}
.input-base .input-base-item .rs-form-group .custom-input-2 {
  min-width: 5.625rem;
}
.input-base .custom-input-1 {
  width: auto !important;
}
.input-base .custom-input-1-icon {
  background-color: var(--rs-btn-default-bg);
}
.input-base .custom-input-2 {
  width: 22% !important;
  float: left;
  margin-right: 0.625rem;
}
.input-base .custom-input-2 input,
.input-base .custom-input-2 span {
  text-align: right;
}
.input-base .custom-input-2 *:focus-visible {
  outline: none;
}
.input-base .custom-input-3 {
  width: auto !important;
}
.input-base .custom-input-3 input,
.input-base .custom-input-3 span {
  text-align: right;
}
.input-base .custom-input-4 {
  width: 100%;
  justify-content: flex-end;
  line-height: 2.375rem;
  cursor: pointer;
}
.input-base .custom-input-4 > div {
  margin-right: 0.375rem;
}
.input-base .date-picker-input *:not(svg, .rs-input-group-addon, .rs-form-error-message) {
  text-align: right;
  width: 100%;
}
.input-base .date-picker-input .rs-picker-date > .rs-input-group.rs-input-group-inside .rs-input {
  padding-right: 0.6875rem;
}
.input-base .date-picker-input .rs-input-group-addon {
  background-color: #f7f7fa;
}
.input-base .date-picker-input .rs-input-group-addon * {
  color: #575757;
}
.input-base.logo-upload .input-base-item {
  justify-content: flex-start;
}
.input-base.logo-upload .input-base-item .image-uploader {
  width: 4.375rem !important;
  margin-right: 0;
}
.input-base.logo-upload .input-base-item .image-uploader .rs-uploader-trigger-btn {
  margin-top: 0;
  position: relative;
}
.input-base.logo-upload .input-base-item .image-uploader .rs-uploader-trigger-btn .rs-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5625rem;
  z-index: 2;
}
.carousel-with-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.carousel-with-stepper > svg {
  color: #170f40;
  height: 2rem;
}
.carousel-with-stepper .rs-carousel-content {
  width: 16rem;
}
.attached-toggle-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
}
.attached-toggle-input-label {
  margin-top: 0.3125rem;
  font-size: 0.8125rem;
}
.attached-toggle-input-toggle {
  margin-top: 0.3125rem;
  margin-left: 0.625rem;
  margin-right: 3.125rem;
}
.rs-form-error-message {
  font-size: 0.6875rem;
  z-index: 7;
  padding: 0.0625rem 0.4375rem;
}
form.hide-error-msg .rs-form-error-message-wrapper {
  display: none;
}
.rs-picker-error > .rs-input-group {
  border-color: #e5e5ea !important;
}
.hide-error-msg > .rs-form-error-message-wrapper {
  display: none;
}
.rs-input-group:has(.rs-form-error-message) {
  outline: 1px solid red;
  box-shadow: 0px 0px 3px 1px red;
}
.rs-input-group:has(.rs-form-error-message) .rs-form-error-message {
  display: none;
}
.rs-input-group:has(.rs-form-error-message):hover {
  border-color: var(--rs-border-primary) !important;
}
.rs-input-group-focus:has(.rs-form-error-message),
.rs-input-group:focus-within:has(.rs-form-error-message) {
  outline: 1px solid red;
  box-shadow: 0px 0px 3px 2px red;
}
.rs-input-group-focus:has(.hide-error-msg),
.rs-input-group:focus-within:has(.hide-error-msg) {
  outline: 3px solid var(--rs-color-focus-ring);
  box-shadow: unset;
}
.rs-input-group:has(.hide-error-msg) {
  outline: unset;
  box-shadow: unset;
}
.rs-input-group-focus .rs-form-error-message {
  display: block !important;
}
.rs-form-control:has(.rs-form-error-message):not(.hide-error-msg) .rs-picker-toggle {
  outline: 1px solid red;
  box-shadow: 0px 0px 3px 1px red;
}
.rs-form-control:has(.rs-form-error-message):not(.hide-error-msg) .rs-picker-toggle:hover {
  border-color: var(--rs-border-primary) !important;
}
.rs-form-control:has(.rs-form-error-message):not(.hide-error-msg) .rs-picker-toggle:focus {
  border-color: var(--rs-border-primary) !important;
}
.rs-form-control:has(.rs-form-error-message):not(.hide-error-msg) .rs-picker-toggle-active {
  outline: 1px solid red;
  box-shadow: 0px 0px 3px 2px red;
  border-color: var(--rs-border-primary) !important;
}
.rs-form-control:has(.rs-form-error-message):not(.hide-error-msg) .rs-picker-toggle-active:hover {
  border-color: var(--rs-border-primary) !important;
}
.rs-form-control:has(.rs-form-error-message):not(.hide-error-msg) .rs-form-error-message {
  display: none;
}
.entity-list-header .rs-stack,
.entity-list-row {
  gap: 0.3125rem;
}
.entity-list-header {
  font-weight: 700;
  margin-top: 1.875rem;
}
.entity-list-row {
  cursor: pointer;
  padding: 0.625rem 0 0.625rem 0;
  position: relative;
}
.entity-list-row .rs-stack-item:not(:last-child) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
}
.entity-list-row .actions-dropdown {
  float: right;
}
.entity-list-row .actions-dropdown button {
  padding: 0.5rem 0.625rem;
  cursor: default;
}
.entity-list-row .actions-dropdown button:hover,
.entity-list-row .actions-dropdown button:active,
.entity-list-row .actions-dropdown button:focus {
  background-color: var(--rs-btn-default-bg);
}
.entity-list-row .actions-dropdown button svg {
  font-size: 1.25rem;
}
.entity-list-row .actions-dropdown ul svg {
  transform: translateY(15%);
  margin-right: 0.5rem;
}
.entity-list-row .actions-dropdown .disabled {
  cursor: not-allowed;
  color: #c5c6c7;
}
.entity-list-row:hover::before {
  content: '';
  position: absolute;
  left: -1.875rem;
  right: -1.875rem;
  top: -1px;
  bottom: -1px;
  background: rgba(115, 222, 168, 0.08);
}
.entity-list-row:hover .actions-dropdown button {
  background-color: var(--rs-btn-default-hover-bg);
}
.entity-list-row:hover .actions-dropdown button:hover,
.entity-list-row:hover .actions-dropdown button:active,
.entity-list-row:hover .actions-dropdown button:focus {
  background-color: var(--rs-btn-default-hover-bg);
}
.entity-list-divider {
  margin: 0;
}
.login-bg {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.875rem;
  padding: 3.125rem;
  background-color: #e7eaec;
}
.login-bg .auth-bg {
  width: 100%;
  height: 100%;
  position: fixed;
}
.login-bg .form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #fff;
  padding: 1.875rem;
  width: 100%;
  max-width: 28.125rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
}
.login-bg .form-container .logo {
  height: 4.6875rem;
  margin-bottom: 0.625rem;
  max-width: 100%;
}
.login-bg .form-container h2 {
  font-size: 1rem;
}
.login-bg .form-container p {
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.login-bg .form-container form {
  width: 100%;
}
.login-bg .form-container form .rs-form-group {
  margin-bottom: 0.9375rem !important;
}
.login-bg .form-container form .rs-form-group .rs-form-control-wrapper {
  width: 100%;
}
.login-bg .form-container form .rs-form-group .rs-form-control-wrapper input {
  width: 100%;
}
.login-bg .form-container form .rs-form-group .rs-form-control-label {
  display: flex;
  gap: 0.625rem;
}
.login-bg .form-container form .rs-form-group .rs-form-control-label p {
  text-align: justify;
}
.login-bg .form-container form .rs-form-group .rs-form-control-label a {
  color: #3b365c;
  text-decoration: underline;
  font-weight: 500;
}
.login-bg .form-container form .link-container {
  display: flex;
  justify-content: flex-end;
}
.login-bg .form-container form .link-container a {
  text-decoration: underline;
}
.login-bg .form-container form button {
  margin-top: 0.625rem;
  width: 100%;
  justify-content: center;
}
.login-bg .centered-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
.login-bg .centered-text a {
  text-decoration: underline;
}
.main-container {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  padding-top: 4.375rem;
  background-color: #e7eaec;
}
.main-container .rs-header {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #fff;
  height: 4.375rem;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
}
.main-container .rs-header .hamburger {
  display: none;
}
.main-container .rs-header .header-logo-container {
  height: 100%;
}
.main-container .rs-header .header-logo-container .header-logo {
  height: 100%;
  cursor: pointer;
}
.main-container .rs-header .header-logo-container .header-logo img {
  height: 100%;
}
.main-container .rs-header .header-logo-container .header-logo-text {
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1875rem;
  margin-bottom: 0.3125rem;
  height: 1.25rem;
}
.main-container .rs-header .header-logo-container .header-logo-text img {
  height: 120%;
}
.main-container .rs-header .rs-avatar .rs-icon {
  font-size: 16px;
  height: 16px;
}
.main-container .rs-sidebar {
  flex: none !important;
  width: fit-content !important;
  position: sticky;
  top: 100;
}
.main-container .rs-sidebar .sidebar-divider {
  margin: 5px 10px;
  background: rgba(255, 255, 255, 0.15);
}
.main-container .rs-sidebar .sidebar-item-with-link {
  display: flex;
}
.main-container .rs-sidebar .sidebar-item-with-link > svg:nth-of-type(2) {
  margin-left: 0.625rem;
}
.main-container .rs-sidebar .rs-sidenav {
  display: flex;
  flex-direction: column;
  background-color: #3b365c;
  height: 100%;
}
.main-container .rs-sidebar .rs-sidenav-collapse-in {
  width: 14.6875rem;
}
.main-container .rs-sidebar .rs-sidenav .rs-sidenav-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  padding: 1rem 0;
}
.main-container .rs-sidebar .rs-sidenav .rs-sidenav-body .rs-sidenav-item {
  color: #fff;
}
.main-container .rs-sidebar .rs-sidenav .rs-sidenav-body .rs-sidenav-item .rs-icon {
  transition: color 0.15s;
  color: #73dea8;
  top: 1rem;
}
.main-container .rs-sidebar .rs-sidenav .rs-sidenav-body .rs-sidenav-item:hover {
  background-color: #170f40;
}
.main-container .rs-sidebar .rs-sidenav .rs-sidenav-body .rs-sidenav-item-active {
  background-color: #170f40;
}
.main-container .rs-sidebar .rs-sidenav .rs-sidenav-body .linksContainer a {
  padding-left: 0.9375rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-size: 0.875rem;
  opacity: 0.65;
}
.main-container .rs-sidebar .rs-sidenav .rs-sidenav-toggle {
  display: none;
}
.main-container .rs-sidebar .rs-sidenav-collapse-out .rs-sidenav-body .rs-sidenav-item .rs-icon {
  color: #fff;
}
.main-container .rs-container {
  height: 100%;
}
.main-container .rs-container .rs-content {
  background-color: #e7eaec;
  padding: 1.25rem;
  position: relative;
  overflow-y: scroll;
}
.main-container .rs-container .rs-content:not(.forecast)::-webkit-scrollbar {
  display: none;
}
.main-container .rs-container .rs-content.forecast::-webkit-scrollbar {
  width: 0.75rem;
  background-color: lightgrey;
}
.main-container .rs-container .rs-content.forecast::-webkit-scrollbar-thumb {
  background-color: #575757;
  border-radius: 6px;
}
.main-container .rs-container .rs-content > img {
  position: absolute;
  width: calc(100% + 1.25rem);
  left: -1.25rem;
  top: -1.25rem;
  min-height: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 60.625rem;
  display: flex;
  flex-direction: column;
}
.main-container .rs-container .rs-content .rs-content-wrapper.has-left-item {
  flex-direction: row;
  max-width: 73.125rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .top-item {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper .top-item .rs-nav-horizontal {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper .top-item a {
  border: none;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item {
  width: 15.625rem;
  height: calc(100dvh - 6.875rem);
  position: sticky;
  top: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .rs-nav-vertical {
  position: fixed;
  width: inherit;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .rs-nav-vertical .rs-nav-item {
  justify-content: right;
  padding-left: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .rs-nav-vertical .rs-nav-item .icon-checked {
  margin-left: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .rs-nav-vertical .rs-nav-item .icon-checked-error {
  margin-left: 0.625rem;
  color: #dc1f1f;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .rs-nav-vertical .rs-nav-item .icon-checked-success {
  margin-left: 0.625rem;
  color: #28aa68;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar {
  position: absolute;
  height: inherit;
  width: inherit;
  background-color: #fff;
  top: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .scrollable-area {
  height: calc(100% - 8.625rem);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .scrollable-area::-webkit-scrollbar {
  width: 0.3125rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .scrollable-area::-webkit-scrollbar-thumb {
  background-color: #3b365c;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .property-selector {
  width: 13.625rem;
  margin: 1rem 1rem 0 1rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .rs-divider {
  margin: 1rem 0 0 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .scenario-divider > span {
  display: block;
  margin: 1.5rem 0 0.125rem 1rem;
  font-weight: bold;
  font-size: 14px;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .scenario-divider .rs-divider {
  margin-top: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .scenario-container {
  min-height: 6.25rem;
  max-height: inherit;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .scenario-container::-webkit-scrollbar {
  display: none;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 15.625rem;
  min-height: 4.25rem;
  background-color: #fff;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .rs-divider {
  margin-top: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .rs-stack:nth-of-type(2) {
  padding: 1rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .rs-stack:nth-of-type(2) > div:nth-of-type(2) {
  flex-grow: 1;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .rs-stack:nth-of-type(2) > div:nth-of-type(2) button {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .edit-forecast-button {
  padding: 0.5rem 1.875rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .edit-forecast-button svg {
  margin-right: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .rs-stack:nth-of-type(1) {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .rs-stack:nth-of-type(1) .rs-stack-item:nth-child(1) {
  flex-grow: 0.2;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .rs-stack:nth-of-type(1) .rs-stack-item:nth-child(2) {
  flex-grow: 0.8;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer .rs-picker-check {
  width: 1.875rem;
  margin: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar-footer > button {
  margin: 1rem;
  flex-grow: 1;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .rs-panel {
  background-color: #fff;
  border-radius: 0.375rem;
  padding: 0.9375rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .left-item .forecast-sidebar .rs-panel .rs-panel-body {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item {
  flex: 1;
  min-width: 0;
  height: inherit;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content {
  position: relative;
  padding: 1.875rem;
  min-height: 10rem;
  background-color: #fff;
  height: inherit;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content.transparent {
  background: transparent;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content h2 {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content-header {
  text-align: left;
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  line-height: 2.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content .loading {
  min-height: 25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content-footer {
  background-color: #fff;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content-footer .rs-divider {
  margin: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content-footer .rs-btn-group {
  float: right;
  padding: 1.5625rem 2.5rem 1.5625rem 1.5625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content-footer .rs-btn-group button {
  border-radius: 0.375rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content-footer .rs-btn-group button:first-of-type {
  margin-right: 0.9375rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.admin-page {
  position: relative;
  height: calc(100dvh - 6.875rem);
}
.main-container .rs-container .rs-content .rs-content-wrapper.admin-page .search-user {
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
  width: 18.75rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.admin-page .no-entries {
  text-align: center;
  margin: 2.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.admin-page .entity-list-header {
  margin-top: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.admin-page .entity-list-row {
  min-height: 3.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.admin-page .admin-footer {
  color: #999;
}
.main-container .rs-container .rs-content .rs-content-wrapper.admin-page .admin-footer .rs-stack-item:last-of-type {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.main-container .rs-container .rs-content .rs-content-wrapper.taxsubjects-page .no-tax-subjects-message {
  text-align: center;
  margin: 2.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.add-taxsubject-message .page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  min-height: 18.75rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .no-properties-msg {
  text-align: center;
  margin: 2.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .property-type {
  margin-bottom: 1.875rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .property-type .rs-stack-item {
  overflow: visible !important;
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .property-type .rs-stack-item > * {
  overflow: visible;
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .tax-subject-dropdown {
  width: 13.75rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .tax-subject-dropdown .rs-stack {
  width: inherit;
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .tax-subject-dropdown .rs-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .tax-subject-dropdown .rs-btn .rs-picker-toggle-caret {
  top: 50%;
  transform: translateY(-50%);
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .tax-subject-dropdown .rs-btn .rs-picker-toggle-clean {
  top: 50%;
  transform: translateY(-40%);
}
.main-container .rs-container .rs-content .rs-content-wrapper.property-page .tax-subject-dropdown svg {
  margin-right: 0.375rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content {
  background-color: #e7eaec;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .back-btn {
  margin-bottom: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-bottom: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content h2 .title-city-span {
  font-size: 1rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .tax-subject-dropdown {
  width: 13.75rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .tax-subject-dropdown .rs-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .tax-subject-dropdown .rs-btn .rs-stack {
  width: 10.9375rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .tax-subject-dropdown .rs-btn .rs-picker-toggle-caret {
  top: 50%;
  transform: translateY(-50%);
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .tax-subject-dropdown svg {
  margin-right: 0.375rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section {
  margin-top: 2.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .section-label {
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
  font-weight: 700;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack {
  margin-bottom: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item {
  border-radius: 2px;
  box-shadow: 3px 3px 9px 9px #00000007;
  align-self: stretch;
  flex: 1;
  min-width: 12.5rem;
  overflow: hidden;
  background-color: #fff;
  padding: 0.9375rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item p {
  line-height: 1;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .rs-placeholder-paragraph-rows {
  height: 1.875rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .label {
  display: flex;
  justify-content: space-between;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .value {
  font-size: 1.5rem;
  margin: 0.3125rem 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .value.positive {
  color: #33a02c;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .value.negative {
  color: #d62728;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .info {
  font-size: 0.625rem;
  color: rgba(51, 51, 51, 0.5);
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .graph-container {
  height: 3.5rem;
  margin-top: 0.625rem;
  overflow: hidden !important;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .graph-container canvas {
  width: 100% !important;
  height: 100% !important;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .action-panel {
  background-color: #3b365c;
  color: #fff;
  margin-bottom: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .action-panel:last-of-type {
  margin-bottom: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .action-panel .click-input {
  justify-content: space-between;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .action-panel .click-input .rs-flex-box-grid-item {
  width: auto !important;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .action-panel .click-input .rs-flex-box-grid-item .action {
  color: #fff !important;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .click-input .rs-flex-box-grid-item:has(span) {
  display: flex;
  justify-content: flex-end;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data {
  background-color: #fff;
  display: flex;
  padding: 1.25rem;
  gap: 2.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data table {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data table tr td {
  padding-bottom: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data table tr td:first-of-type {
  padding-right: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data table tr:last-of-type td {
  padding-bottom: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data .info-container {
  flex: 1;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data .map-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data .map-container .map {
  z-index: 1;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 12.5rem;
  background-color: #f2f2f2;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data .map-container .map .leaflet-container {
  height: 100%;
  width: 100%;
  min-height: 12.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data .map-container .map .location-not-found {
  text-align: center;
  margin: 2.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data .map-container .address table tr td:last-of-type {
  text-align: right;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast {
  background-color: #fff;
  display: flex;
  padding: 1.25rem;
  gap: 2.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast .forecast-graph {
  flex: 1;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast .forecast-details {
  width: 325px;
  padding: 1.25rem;
  background-color: #f7f7fa;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast .forecast-details h3 {
  font-size: 1rem;
  margin-bottom: 0.625rem;
  font-weight: 500;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast .forecast-details h3 .warning-icon {
  color: #dc1f1f;
  height: 1.25rem;
  margin-left: 0.625rem;
  transform: translateY(14%);
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast .forecast-details h3 .color-indicator {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #73dea8;
  border-radius: 50%;
  margin-right: 0.625rem;
  transform: translateY(20%);
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast .forecast-details p {
  line-height: 1.75rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast .forecast-details .years-until-profit {
  font-weight: 700;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast .forecast-details .years-until-profit.is-hobby {
  color: #dc1f1f;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .no-forecast-data {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
  min-height: 12.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info {
  background-color: #fff;
  display: flex;
  gap: 2.5rem;
  padding: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block {
  flex: 1;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block table {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block table tr td {
  padding-bottom: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block table tr td:first-of-type {
  padding-right: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block table tr:last-of-type td {
  padding-bottom: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .promotion-card {
  background: linear-gradient(139.54deg, #73DEA8 5.54%, #14C044 93.59%);
  padding: 1.25rem;
  margin-top: 2.5rem;
  display: flex;
  align-items: flex-end;
  position: relative;
  border-radius: 6px;
  color: #fff;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .promotion-card > div:first-of-type {
  width: 37.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .promotion-card > div:first-of-type p {
  margin-top: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .promotion-card > div:nth-of-type(2) {
  width: 16.875rem;
  height: 5rem;
  position: relative;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .promotion-card > div:nth-of-type(2) button {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  color: #73dea8;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .promotion-card > div:nth-of-type(2) img {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: -1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .promotion-card svg {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 1.875rem;
  height: 1.875rem;
  opacity: 0.4;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page > .main-item > .page-content > .rs-stack > .rs-stack-item:first-of-type {
  width: 55%;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page > .main-item > .page-content > .rs-stack > .rs-stack-item:nth-of-type(2) {
  width: 40%;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page > .main-item > .page-content > .rs-stack > .rs-stack-item:nth-of-type(2) .tax-subject-dropdown {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page > .main-item > .page-content > .rs-stack > .rs-stack-item:nth-of-type(2) .tax-subject-dropdown .rs-picker-toggle > .rs-stack {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page > .main-item > .page-content > .rs-stack > .rs-stack-item:nth-of-type(2) .rs-picker-toggle-value {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 1.5rem);
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page {
  gap: 1.25rem;
  max-width: 112.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page.empty {
  height: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .page-content {
  min-height: 0;
  padding: 0;
  background-color: transparent;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .page-content-header {
  margin-bottom: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1.875rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container:last-of-type {
  margin-bottom: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel {
  height: 3.125rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .rs-btn-active {
  background-color: var(--rs-btn-default-bg);
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .rs-btn-active > svg {
  color: black !important;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .rs-btn-active:hover {
  background-color: var(--rs-btn-default-hover-bg);
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .rs-btn-active:hover > svg {
  color: black;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .rs-btn > svg {
  color: rgba(211, 211, 211, 0.8);
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .rs-btn:hover:not(.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .rs-btn-active),
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .rs-btn:focus:not(.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .rs-btn-active) {
  background-color: var(--rs-btn-default-bg);
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .actions-dropdown .rs-dropdown-item .collapse-actions {
  width: 100%;
  display: flex;
  justify-content: center;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .actions-dropdown .rs-dropdown-item .collapse-actions button {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .controls-panel .actions-dropdown .rs-dropdown-item.export-to-pdf {
  margin: 0 0.3125rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .graph-container {
  height: 17.5rem !important;
  margin-top: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.historical-data-page .revenue-heading .info-icon {
  margin-left: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.historical-data-page .distribution-of-expenses {
  padding: 0 0 1.5625rem 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper.historical-data-page .distribution-of-expenses .attached-toggle-input {
  margin-bottom: 0;
  margin-top: 0.3125rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper.historical-data-page .distribution-of-expenses .input-base:last-of-type .input-base-item {
  width: 15.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-heading {
  margin-bottom: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-heading h2 span {
  font-weight: 400;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-heading .info-icon {
  margin-left: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .year-selector {
  margin: 1.25rem 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .year-selector label {
  margin-right: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section {
  border: 2px solid #333333;
  border-bottom: none;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section:last-of-type {
  border-bottom: 2px solid #333333;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section.has-bottom-margin {
  margin-bottom: 2.5rem;
  border-bottom: 2px solid #333333;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section.is-disabled {
  background-color: #d9d9d966;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section.has-bold-label .input-base-label {
  font-weight: 700;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-header {
  padding: 0.3125rem 1.25rem;
  font-weight: 700;
  border-bottom: 2px solid #333333;
  display: flex;
  align-items: center;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-header .info-icon {
  width: 0.9375rem;
  margin-left: 0.625rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body {
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .info-icon {
  margin-left: 1.25rem;
  margin-top: 0.5rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .rs-stack {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .rs-checkbox.half-width {
  width: 26.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .property-section {
  width: 100%;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .property-section-row {
  width: 100%;
  margin-bottom: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .property-section-row:last-of-type {
  margin-bottom: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .e1b-section-subtitle {
  margin-top: 0.5rem;
  font-weight: 700;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .rs-picker-disabled {
  opacity: 1;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .rs-picker-disabled .rs-btn-disabled .rs-picker-toggle-value {
  color: #333333;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base:first-of-type {
  margin-top: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base .rs-form-group {
  margin-right: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base .rs-form-group input {
  background-color: transparent;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base:not(.label-on-top) {
  gap: 1.875rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base:not(.label-on-top) .label-container {
  width: 26.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base:not(.label-on-top) .input-base-item {
  flex: 1;
  align-items: flex-start;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base:not(.label-on-top) .input-base-item .prefix.rs-input-group-addon {
  font-weight: 700;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base:not(.label-on-top) .input-base-item .info-icon {
  align-self: first baseline;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base.label-on-top .input-base-label {
  margin-top: 0;
  padding-left: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-section-body .input-base.label-on-top .input-base-item .rs-form-group {
  margin-left: 0;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-comment-section {
  margin-top: 1.25rem;
}
.main-container .rs-container .rs-content .rs-content-wrapper .e1b-footer .rs-btn-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.scenario-card {
  padding: 0.9375rem 0.9375rem 1.25rem 0.9375rem;
  border-bottom: 1px solid var(--rs-divider-border);
  cursor: pointer;
  gap: 1.25rem;
}
.scenario-card:hover {
  background: rgba(115, 222, 168, 0.08);
}
.scenario-card .colored-circle {
  height: 1.25rem;
}
.scenario-card svg {
  margin-top: 0.3125rem;
}
.scenario-card-title {
  font-weight: 600;
  font-size: 16px;
}
.scenario-card-info {
  font-size: 11px;
}
.scenario-card-info.hobbyism {
  color: #dc1f1f;
}
.scenario-card .warning-icon {
  height: 1.25rem;
  color: #dc1f1f;
}
.scenario-card > .rs-stack-item:nth-child(2) {
  width: 11.25rem;
}
.scenario-card > .rs-stack-item:nth-child(2) .scenario-card-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.scenario-card > .rs-stack-item:nth-child(2) .scenario-card-title > span {
  margin-left: 5px;
  font-size: 13px;
}
.small-business-info {
  border-radius: 6px;
  background-color: #73dea8;
  margin-top: 0.5rem;
  width: fit-content;
  padding: 0.5rem;
}
.empty-forecast-page {
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty-forecast-page p {
  margin-top: 1rem;
}
.border-cover {
  top: 24.75rem;
  height: 1.4375rem;
  right: 1.3rem;
  position: absolute;
  width: 0.625rem;
  background: #fff;
}
.border-cover.mobile {
  top: 23.82rem;
  right: 0.314rem;
}
.border-cover.mobile.closed-graph {
  top: 5.09rem;
}
.border-cover.small-screen {
  top: 25.37rem;
  right: 1.9rem;
}
.border-cover.small-screen.closed-graph {
  top: 6.6rem;
}
.border-cover.closed-graph {
  top: 6rem;
}
.button-cover {
  top: 24.75rem;
  width: 13.1875rem;
  height: 1.4375rem;
  position: absolute;
  background: #fff;
}
.button-cover.mobile {
  top: 23.82rem;
  left: 0.97rem;
  width: 8.4375rem;
}
.button-cover.mobile.closed-graph {
  top: 5.09rem;
}
.button-cover.small-screen {
  top: 25.37rem;
  left: 2.49rem;
}
.button-cover.small-screen.closed-graph {
  top: 6.6rem;
}
.button-cover.closed-graph {
  top: 6rem;
}
.simple-table {
  overflow-x: scroll;
  width: 100%;
  margin-top: 1rem;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-collapse: collapse;
  position: relative;
}
.simple-table::-webkit-scrollbar {
  height: 0.5rem;
  background-color: lightgrey;
}
.simple-table::-webkit-scrollbar-thumb {
  background-color: #575757;
  border-radius: 6px;
}
.simple-table .historical-data-button {
  left: 13.1375rem;
  width: 6.25rem;
  position: absolute;
  height: 1.4375rem;
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  border: 0.1px solid lightgray;
  font-size: 12px;
}
.simple-table .historical-data-button.dark {
  background-color: #333333;
  color: #fff;
}
.simple-table .historical-data-button .plus-icon {
  margin-right: 0.9375rem;
}
.simple-table .label-container {
  width: 100%;
  outline: 1px solid lightgray;
  padding-left: 0.3125rem;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.5);
}
.simple-table .label-container.single-row {
  height: 100%;
}
.simple-table .label-container > .rs-stack {
  width: inherit;
  height: inherit;
}
.simple-table .label-container > .rs-stack > .rs-stack-item:nth-of-type(1) {
  width: 10.25rem;
}
.simple-table .label-container > .rs-stack .row-label {
  width: 10.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  font-weight: 400;
  white-space: nowrap;
}
.simple-table .label-container > .rs-stack .color-indicator {
  width: 0.3125rem;
  height: 1.875rem;
}
.simple-table .margin-on-top {
  margin-top: 1.4375rem;
}
.simple-table table {
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  color: black;
  font-size: 13px;
}
.simple-table table col:first-of-type {
  width: 13.125rem;
}
.simple-table table col {
  width: 6.25rem;
}
.simple-table table td,
.simple-table table th {
  border: 0.1px solid lightgray;
  height: 1.875rem;
}
.simple-table table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  padding-right: 0.3125rem;
  text-align: right;
}
.simple-table table td {
  background: inherit;
  text-align: right;
  padding-right: 0.3125rem;
}
.simple-table table tbody th {
  text-align: left;
  position: relative;
  border: 0.1px solid lightgrey;
  border-collapse: separate;
}
.simple-table table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background: #fff;
  padding: 0;
}
.simple-table table thead th:first-child .label-container {
  height: 100%;
}
.simple-table table thead th:first-child:hover {
  outline: none;
}
.simple-table table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
.version-label {
  position: fixed;
  bottom: 0.3125rem;
  right: 0.3125rem;
  color: rgba(0, 0, 0, 0.13);
  font-size: 12px;
  font-weight: 400;
  pointer-events: none;
}
.forecast-edit-btn {
  display: flex;
  margin: 1rem auto;
}
.quick-check-form .forecast-sidebar-footer button {
  padding: 0.5rem 2.3125rem;
}
.quick-check-form > div:nth-last-of-type(2) {
  margin-bottom: 1rem;
}
.quick-check-form .inline-edit-input span {
  line-height: 1.25rem;
}
.scenario-form {
  height: 100%;
}
.scenario-form > div {
  height: 100%;
}
.scenario-form > div > div:nth-of-type(1) {
  height: inherit;
}
.scenario-form-header {
  height: 3.5625rem;
  border-bottom: 1px solid var(--rs-divider-border);
}
.scenario-form-header-title {
  width: 100%;
}
.scenario-form-header > .rs-stack-item {
  height: inherit;
}
.scenario-form-header > .rs-stack-item:nth-of-type(2) {
  width: 3.125rem;
  border-left: 1px solid var(--rs-divider-border);
}
.scenario-form-header > .rs-stack-item:nth-of-type(2) button {
  height: 3.5rem;
  width: 3.0625rem;
  border-radius: 0;
}
.scenario-form-header > .rs-stack-item:nth-of-type(2) button:hover,
.scenario-form-header > .rs-stack-item:nth-of-type(2) button:focus {
  background-color: inherit;
}
.scenario-form-header > .rs-stack-item:nth-of-type(1) {
  flex-grow: 1;
}
.scenario-form-header > .rs-stack-item:nth-of-type(1) * {
  height: inherit;
  border: none;
  outline: none;
}
.scenario-form-header > .rs-stack-item:nth-of-type(1) svg {
  margin-left: 0.625rem;
  width: 1.25rem;
}
.scenario-form-header > .rs-stack-item:nth-of-type(1) input {
  height: 3.5rem;
  font-size: 16px;
  font-weight: 600;
}
.scenario-form-header .scenario-name-input {
  width: 100%;
}
.scenario-form .inline-edit-input span {
  line-height: 1.25rem;
}
.scenario-form .input-base .invisible {
  display: none;
}
.scenario-form .forecast-sidebar-footer {
  display: flex;
  width: 100% !important;
  justify-content: flex-end;
  padding-right: 1rem;
  border-top: 1px solid lightgrey;
}
.scenario-form .forecast-sidebar-footer button {
  flex-grow: 0 !important;
}
.scenario-form .scrollable-area {
  height: calc(100% - 7.8rem) !important;
}
.scenario-form .scrollable-area > div:last-of-type {
  margin-bottom: 1rem;
}
.scenario-form .scrollable-area .rs-panel {
  padding: 0 !important;
  margin-top: 1rem;
  border-radius: 0 !important;
}
.scenario-form .scrollable-area .rs-panel-header {
  padding: 0;
}
.scenario-form .scrollable-area .rs-panel-body {
  padding: 0;
  gap: 0;
}
.scenario-form .scrollable-area .rs-panel-btn {
  border-radius: 0;
}
.tax-subjects-list-name-column {
  overflow: hidden;
  text-overflow: ellipsis;
}
.additional-cost-item {
  justify-content: space-between;
}
.additional-cost-item .page-subtitle {
  margin-top: 0;
  font-size: 1.125rem;
}
.additional-cost-item button {
  margin-right: 3.125rem;
}
.invitation-modal .rs-modal-header .rs-modal-title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
}
.invitation-modal .rs-modal-body {
  margin-bottom: 1.25rem;
}
.invitation-modal .rs-modal-body .invites-list-header .rs-stack-item {
  font-weight: 700;
}
.invitation-modal .rs-modal-body .rs-divider {
  margin: 0.625rem 0;
}
.invitation-modal .rs-modal-body .no-accepters-message {
  text-align: center;
}
.invitation-modal .rs-modal-body .actions-div {
  min-height: 9.375rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.625rem;
}
.invitation-modal .rs-modal-footer {
  display: flex;
}
.invitation-modal .rs-modal-footer p {
  flex: 1;
  text-align: left;
}
.onboarding-text {
  margin: 1.25rem 0 1.875rem 0;
}
.accepter-modal .rs-modal-header .rs-modal-title {
  font-size: 1.25rem;
  font-weight: 700;
}
.dashboard-green-bg {
  margin-top: 1rem;
  background-color: #73dea8;
  padding: 1.25rem;
  border-radius: 6px;
  max-width: 37.5rem;
}
.dashboard-panel {
  margin: 2rem;
  margin-left: 0;
  width: 100%;
  max-width: 37.5rem;
}
.dashboard-panel-title {
  font-weight: 500;
}
.dashboard-panel.active {
  border: solid;
}
.dashboard-panel .rs-icon {
  width: 1.25rem;
}
.dashboard-panel-description {
  display: flex;
  justify-content: space-between;
}
.dashboard-panel-description button {
  margin-left: 0.625rem;
}
.rs-drawer-wrapper {
  width: 18.75rem;
  right: 0;
  left: auto;
  margin-top: 4.375rem;
}
.rs-drawer-wrapper .rs-drawer {
  margin-top: 4.375rem;
  padding-bottom: 4.375rem;
  width: 18.75rem;
}
.rs-drawer-wrapper .rs-drawer .rs-drawer-body {
  padding: 2.5rem 1.25rem;
}
.chartjs-tooltip-custom {
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-shadow-overlay);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-shadow-overlay);
  padding: 5px 10px;
  text-align: right;
}
.payment-cancel-reasons {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.payment-page {
  color: white;
}
.payment-page > div > div {
  background: linear-gradient(45deg, #53c836, #73dea8);
}
.payment-page-plansContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-page-cancel-plan-text {
  font-size: 1rem;
  margin: 1rem;
  margin-right: 8.125rem;
  margin-top: 4rem;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
}
.payment-page-cancel-plan-text.pro {
  margin-right: 25rem;
}
.payment-page-cancel-plan-text.basic {
  margin-right: 42.5rem;
}
.ancillary-purchase-cost-header {
  margin-top: 1rem;
}
.pricing-features-card {
  width: 15rem;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: black;
}
.pricing-features-card-name,
.pricing-features-card-price {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.pricing-features-card-price span {
  margin-left: 0.5rem;
  font-weight: 300;
  font-size: 1.5rem;
}
.pricing-features-card-primaryFeatures,
.pricing-features-card-secondaryFeatures {
  margin-top: 1.5rem;
}
.pricing-features-card-primaryFeatures > div,
.pricing-features-card-secondaryFeatures > div {
  display: flex;
  align-items: center;
  margin: 0.5rem;
}
.pricing-features-card-primaryFeatures > div div:nth-child(1),
.pricing-features-card-secondaryFeatures > div div:nth-child(1) {
  flex: 0 0 10%;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
.pricing-features-card-primaryFeatures {
  font-weight: 600;
}
.pricing-features-card-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem;
  margin-top: 5rem;
  font-size: 1rem;
}
.pricing-features-card-footer div {
  height: 2.25rem;
}
.plan-details-container > div {
  margin-top: 1rem;
}
.plan-details-container > div > div > p {
  padding: 0;
  margin: 0;
  font-style: italic;
}
.image-uploader .uploader-btn {
  height: 6.25rem;
  width: 6.25rem;
}
.rs-modal-footer .rs-btn {
  margin-left: 0.625rem;
}
.rs-toggle-disabled .rs-toggle-presentation::after {
  cursor: not-allowed;
}
.rs-drawer {
  opacity: 1 !important;
}
.main-container .rs-header {
  z-index: 1051;
}
.rs-modal-backdrop {
  z-index: 1052;
}
.rs-modal-wrapper {
  z-index: 1053;
}
.rs-drawer-title {
  line-height: 34px;
}
.rs-stack .rs-stack-item {
  max-width: 100%;
}
@media (min-width: 1024px) {
  .main-container .rs-container .rs-content.expand-padding-right {
    padding-right: 20rem;
    transition: padding-right 0.3s ease-in-out;
    -webkit-transition: padding-right 0.3s ease-in-out;
    -moz-transition: padding-right 0.3s ease-in-out;
    -o-transition: padding-right 0.3s ease-in-out;
  }
  .main-container .rs-container .rs-content.collapse-padding-right {
    padding-right: 1.25rem;
    transition: padding-right 0.3s ease-in-out;
    -webkit-transition: padding-right 0.3s ease-in-out;
    -moz-transition: padding-right 0.3s ease-in-out;
    -o-transition: padding-right 0.3s ease-in-out;
  }
}
@media (max-width: 1024px) {
  .pricing-features-card {
    float: left;
    margin: 1rem 0.5rem;
  }
  .rs-carousel {
    height: 31.25rem;
    background-color: transparent;
  }
  .payment-page .page-content-header {
    text-align: center !important;
  }
  .payment-page .payment-page-cancel-plan-text {
    margin: 2rem auto;
    text-align: center;
    height: 0.5rem;
  }
  html {
    position: fixed;
  }
  body,
  .rs-btn,
  .rs-input {
    font-size: 0.75rem;
  }
  .rs-btn {
    font-size: 0.75rem;
  }
  .login-bg {
    height: auto;
  }
  .main-container {
    padding-top: 4.375rem;
  }
  .main-container .rs-container .rs-sidebar .rs-sidenav .rs-sidenav-body .rs-sidenav-nav a {
    font-size: 0.875rem;
  }
  .main-container .rs-container .rs-content {
    padding: 0.625rem;
  }
  .main-container .rs-container .rs-content:has(.forecast-calculation-page) {
    padding-top: 0;
  }
  .main-container .rs-container .rs-content.expand-padding-right,
  .main-container .rs-container .rs-content.collapse-padding-right {
    padding-right: 0.625rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content-header {
    font-size: 1.125rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper .main-item .page-content {
    padding: 0.625rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content {
    padding: 0;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content h2 {
    font-size: 1.125rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack {
    gap: 0.625rem !important;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item {
    min-width: 10.625rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .label {
    font-size: 0.75rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .value {
    font-size: 1rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack .rs-stack-item .info {
    font-size: 0.5rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data {
    flex-direction: column-reverse;
    gap: 0.625rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data .info-container table tr td {
    width: 50%;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data .info-container table tr td:last-of-type {
    text-align: right;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data .map-container {
    flex-direction: column;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast {
    flex-direction: column-reverse;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast .forecast-details {
    width: 100%;
    text-align: center;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page {
    gap: 0;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container {
    margin-bottom: 0.625rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .top-item {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #e7eaec;
    padding-top: 0.625rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content {
    background-color: #fff;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar {
    position: relative;
    height: calc(100dvh - 145px);
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar h3 {
    font-size: 1.375rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .rs-picker-select {
    width: 100%;
    margin: 0.625rem 0 1.25rem 0;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .rs-divider {
    margin: 0;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar-footer {
    margin-top: 1.25rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar-footer .rs-divider {
    margin-bottom: 1.25rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area {
    height: calc(100% - 131px);
    overflow-y: auto;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area::-webkit-scrollbar {
    display: none;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area .scenario-card {
    padding: 0.625rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area .scenario-card .rs-stack-item:nth-child(2) {
    width: 100%;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area .scenario-card .rs-stack-item:nth-child(2) .scenario-card-title {
    font-size: 0.875rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area .scenario-card .rs-stack-item:nth-child(2) .scenario-card-info {
    font-size: 0.625rem;
    justify-content: space-between !important;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area .scenario-card .rs-stack-item:nth-child(2) .scenario-card-info .rs-stack-item:nth-child(2) {
    width: auto;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area .scenario-card .rs-stack-item svg {
    width: 1.125rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area .scenario-divider span {
    display: block;
    font-size: 1.125rem;
    margin-top: 1.875rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .forecast-sidebar .scrollable-area .scenario-divider .rs-divider {
    margin: 0;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .main-item .page-content .table-and-graph-container .title-stack .rs-stack-item h3 {
    font-size: 1.375rem;
  }
  .rs-drawer-wrapper {
    width: 100%;
  }
  .rs-drawer-wrapper .rs-drawer .rs-drawer-body {
    padding: 0.625rem;
  }
  .forecast-sidebar-footer .edit-forecast-button.rs-btn {
    padding: 0.5rem 0.75rem;
  }
}
@media (max-width: 850px) {
  .input-base:not(.label-on-top) {
    min-width: 25rem;
  }
  .main-container .rs-sidebar .rs-sidenav .rs-sidenav-toggle {
    display: block;
    border-top-color: #170f40;
  }
  .main-container .rs-sidebar .rs-sidenav .rs-sidenav-toggle .rs-sidenav-toggle-button:hover {
    background-color: #170f40;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container {
    padding: 0.3125rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table .button-cover {
    width: 8.5625rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table .historical-data-button {
    left: 8.4375rem;
    width: 5.125rem;
    font-size: 0.625rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table .historical-data-button .plus-icon {
    margin-right: 0.3125rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table .row-label {
    width: 7.5rem;
    height: 15px;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table .color-indicator {
    height: 1.5rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table table {
    font-size: 0.75rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table table td,
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table table th {
    height: 1.5rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table table col:first-of-type {
    width: 8.4375rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table table col {
    width: 5.125rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container .simple-table table tbody th .rs-stack .rs-stack-item svg {
    display: none;
  }
  .main-container .rs-container .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .kpis.rs-stack {
    gap: 0.3125rem !important;
  }
  .main-container .rs-container .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .action-panel .rs-panel-body {
    padding: 0.9375rem 0.625rem;
  }
  .main-container .rs-container .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .object-data {
    padding: 0.625rem;
  }
  .main-container .rs-container .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section .forecast {
    padding: 0.625rem;
  }
  .main-container .rs-container .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info {
    padding: 0.625rem;
  }
}
@media (max-width: 720px) {
  .input-base {
    flex-direction: column;
    position: relative;
  }
  .input-base:not(.label-on-top) {
    min-width: 6.25rem;
  }
  .input-base .label-container label {
    margin-bottom: 0.125rem;
  }
  .input-base.long-label.dropdown-input,
  .input-base.long-label.checkpicker-input,
  .input-base.long-label.toggle-input {
    flex-direction: column;
  }
  .input-base.long-label.dropdown-input .input-base-item,
  .input-base.long-label.checkpicker-input .input-base-item,
  .input-base.long-label.toggle-input .input-base-item {
    max-width: 100%;
  }
  .input-base.long-label.dropdown-input .label-container,
  .input-base.long-label.checkpicker-input .label-container,
  .input-base.long-label.toggle-input .label-container {
    max-width: 100%;
  }
  .input-base.dropdown-input,
  .input-base.checkpicker-input,
  .input-base.toggle-input {
    flex-direction: row;
    align-items: center;
  }
  .input-base.dropdown-input .label-container,
  .input-base.checkpicker-input .label-container,
  .input-base.toggle-input .label-container {
    max-width: 55%;
  }
  .input-base.dropdown-input .label-container label,
  .input-base.checkpicker-input .label-container label,
  .input-base.toggle-input .label-container label {
    padding-left: 0;
  }
  .input-base.dropdown-input .input-base-item,
  .input-base.checkpicker-input .input-base-item,
  .input-base.toggle-input .input-base-item {
    flex: 1;
    max-width: 40%;
  }
  .input-base.indented-input .indent-icon {
    position: absolute;
    top: 50%;
    margin: 0;
    font-size: 1.25rem;
  }
  .input-base.indented-input .label-container label {
    margin-left: 1.875rem;
  }
  .input-base.indented-input .input-base-item {
    padding-left: 1.875rem;
  }
  .input-base.label-on-top .input-base-item .rs-form-group {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .input-base-item:not(.label-on-top) {
    width: 100%;
  }
  .input-base-item:not(.label-on-top) .rs-form-help-text {
    text-align: right;
    font-size: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .input-base-item:not(.label-on-top) > * {
    margin-right: 0;
    margin-left: 0;
  }
  .input-base-item:not(.label-on-top) > svg {
    margin-left: 0.625rem;
    margin-top: 0.375rem;
  }
  .input-base .custom-input-1 {
    width: auto !important;
    align-items: flex-end;
  }
  .input-base .custom-input-1-icon {
    background-color: var(--rs-btn-default-bg);
  }
  .input-base .custom-input-2 {
    width: 22% !important;
    float: left;
    margin-right: 0.625rem;
  }
  .input-base .custom-input-2 input {
    text-align: right;
  }
  .input-base .custom-input-3 {
    width: auto !important;
  }
  .input-base .custom-input-3 input {
    text-align: right;
  }
  .input-base .custom-input-4 {
    width: 100%;
    justify-content: flex-end;
    line-height: 2rem;
    cursor: pointer;
  }
  .input-base .custom-input-4 > div {
    margin-right: 0.375rem;
  }
  .input-base.logo-upload .input-base-item {
    justify-content: flex-start;
  }
  .input-base.logo-upload .input-base-item .image-uploader {
    width: 4.375rem !important;
    margin-right: 0;
  }
  .input-base.logo-upload .input-base-item .image-uploader .rs-uploader-trigger-btn {
    margin-top: 0;
    position: relative;
  }
  .input-base.logo-upload .input-base-item .image-uploader .rs-uploader-trigger-btn .rs-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5625rem;
    z-index: 2;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.forecast-calculation-page .table-and-graph-container h3 {
    font-size: 1.125rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info {
    flex-direction: column;
    gap: 0.3125rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block {
    flex: 1;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block table {
    width: 100%;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block table tr td {
    padding-bottom: 0.3125rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block table tr td:first-of-type {
    width: 12.5rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .dashboard-section.taxsubject .taxsubject-info .info-block table tr:last-of-type td {
    padding-bottom: 0;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .promotion-card > div:first-of-type {
    width: 21.875rem;
  }
  .main-container .rs-container .rs-content .rs-content-wrapper.entity-dashboard-page .page-content .promotion-card > div:first-of-type p {
    position: relative;
    z-index: 1;
  }
}
@media (max-width: 500px) {
  .main-container .rs-header {
    padding: 0.625rem 1.25rem;
  }
  .main-container .rs-header .hamburger {
    display: block;
  }
  .main-container .rs-header .hamburger svg {
    width: 1.25rem;
    font-size: 1.25rem;
  }
  .main-container .backdrop {
    position: fixed;
    background-color: #999;
    opacity: 0.5;
    width: 100vw;
    height: 100dvh;
    z-index: 10;
  }
  .main-container .rs-container-has-sidebar .rs-sidebar {
    position: fixed;
    z-index: 11;
    height: calc(100dvh - 70px);
  }
  .main-container .rs-container-has-sidebar .rs-sidebar .rs-sidenav {
    width: 100vw;
  }
  .main-container .rs-container-has-sidebar .rs-sidebar .rs-sidenav .rs-sidenav-body .rs-sidenav-nav a {
    font-size: 1.125rem;
  }
  .main-container .rs-container-has-sidebar .rs-sidebar .rs-sidenav-collapse-out {
    width: 0;
  }
  .main-container .rs-container-has-sidebar .rs-sidebar .rs-sidenav .rs-sidenav-toggle {
    display: none;
  }
  .main-container .rs-content .rs-content-wrapper.forecast-calculation-page.collapsed .left-item {
    width: 0 !important;
  }
  .main-container .rs-content .rs-content-wrapper.forecast-calculation-page.collapsed .left-item .forecast-sidebar-footer.collapsed {
    width: 0;
  }
  .main-container .rs-content .rs-content-wrapper.admin-page {
    position: static !important;
    height: calc(100dvh - 6.875rem);
  }
  .main-container .rs-content .rs-content-wrapper.admin-page .search-user {
    position: static !important;
    top: 0;
    left: 0;
    margin-bottom: 1.25rem;
  }
  .main-container .rs-content .rs-content-wrapper.admin-page .search-user .rs-input-group-btn {
    position: static !important;
  }
  .main-container .rs-content .rs-content-wrapper.admin-page .search-user .rs-input-group-btn .rs-ripple-pond {
    display: none !important;
  }
}
